var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 5 } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "请输入搜索内容" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.search.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.inputVal,
                    callback: function ($$v) {
                      _vm.inputVal = $$v
                    },
                    expression: "inputVal",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.select,
                        callback: function ($$v) {
                          _vm.select = $$v
                        },
                        expression: "select",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "问题ID", value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: "问题标题", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜 索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重 置")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addProblems },
                },
                [_vm._v("编辑题目")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "dragTable",
          staticClass: "tb-list",
          staticStyle: { width: "40%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "row-key": "problem_id",
          },
        },
        [
          _vm._v(" > "),
          _c("el-table-column", {
            attrs: { label: "题号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", prop: "problem_id" },
          }),
          _c("el-table-column", {
            attrs: { label: "标题", align: "center", prop: "title" },
          }),
        ],
        1
      ),
      _c("add-problem", {
        attrs: {
          show: _vm.addproblemDialog,
          problemList: _vm.dataList,
          contest_id: _vm.editData.contest_id + "",
        },
        on: {
          updateView: _vm.getQuestionByLibraryId,
          close: function ($event) {
            _vm.addproblemDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }