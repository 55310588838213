var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        width: "75%",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("编辑题目")])]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-row",
            { attrs: { gutter: 60 } },
            [
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("待选题目："),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "label-item-left" },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: {
                            width: "250px",
                            "margin-right": "20px",
                          },
                          attrs: { placeholder: "请输入内容" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.queryData.inputVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "inputVal", $$v)
                            },
                            expression: "queryData.inputVal",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "110px" },
                              attrs: { slot: "prepend" },
                              slot: "prepend",
                              model: {
                                value: _vm.queryData.select,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryData, "select", $$v)
                                },
                                expression: "queryData.select",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "问题标题", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "问题id", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-right": "20px",
                          },
                          attrs: { multiple: "", placeholder: "请选择" },
                          on: { change: _vm.handleSeleTag },
                          model: {
                            value: _vm.queryData.seleTag,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "seleTag", $$v)
                            },
                            expression: "queryData.seleTag",
                          },
                        },
                        _vm._l(_vm.tagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info", size: "small" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("筛 选")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "label-item-left" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticClass: "tb-list",
                          attrs: {
                            data: _vm.dataList,
                            border: "",
                            fit: "",
                            "highlight-current-row": "",
                          },
                          on: { "selection-change": _vm.handleSeleChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "55",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "问题ID",
                              align: "center",
                              width: "200",
                              prop: "problem_id",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "问题标题",
                              align: "left",
                              prop: "title",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "标签", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    !scope.row.tagList ||
                                    scope.row.tagList.length === 0
                                      ? [_c("span", [_vm._v("无")])]
                                      : _vm._l(
                                          scope.row.tagList,
                                          function (item) {
                                            return _c(
                                              "span",
                                              { key: item.id },
                                              [
                                                Boolean(item.groupInfo)
                                                  ? [
                                                      _c("span", {
                                                        staticClass: "tag",
                                                        style:
                                                          "backgroundColor" +
                                                          ":" +
                                                          item.groupInfo.color,
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.title
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  : [
                                                      _c("span", {
                                                        staticClass: "tag",
                                                        style:
                                                          "backgroundColor" +
                                                          ":" +
                                                          "#ccc",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.title
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "添加", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.choosetype == 0,
                                            expression:
                                              "scope.row.choosetype == 0",
                                          },
                                        ],
                                        staticClass: "btn-span",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addQues(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("添加")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.choosetype == 1,
                                            expression:
                                              "scope.row.choosetype == 1",
                                          },
                                        ],
                                        staticClass: "btn-hasspan",
                                      },
                                      [_vm._v("已添加")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "label-item-left" },
                    [
                      _c(
                        "el-dropdown",
                        {
                          staticClass: "tb-dropdown",
                          staticStyle: { "margin-top": "20px" },
                          attrs: { trigger: "click" },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "info", size: "medium" } },
                            [
                              _vm._v(" 批量操作 "),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticStyle: { padding: "10px" },
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.enableAccount.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus-outline",
                                  }),
                                  _vm._v("批量添加 "),
                                ]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.disabledAccount.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-delete" }),
                                  _vm._v("批量删除"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pagination.currentPage,
                      "page-sizes": _vm.pagination.pageSizes,
                      "page-size": _vm.pagination.pageSize,
                      layout: _vm.pagination.layout,
                      total: _vm.pagination.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("span", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v("已选题目："),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "haschoose" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "dragTable",
                          staticClass: "tb-list",
                          attrs: {
                            data: _vm.seleTopic,
                            border: "",
                            fit: "",
                            "row-key": "problem_id",
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "题号", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(scope.$index + 1) + " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "ID",
                              align: "center",
                              prop: "problem_id",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "标题",
                              align: "center",
                              prop: "title",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("i", {
                                      staticClass: "el-icon-sort",
                                      staticStyle: {
                                        "font-size": "16px",
                                        "margin-right": "10px",
                                        cursor: "pointer",
                                      },
                                    }),
                                    _c("i", {
                                      staticClass: "el-icon-delete-solid",
                                      staticStyle: {
                                        "font-size": "16px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(scope.row)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "footerparent",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.updateQuestionLibrary },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }