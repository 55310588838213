<template>
  <div class="table" @scroll.passive="getScroll($event)">
    <span class="back-tit">竞赛详情</span>
    <el-tabs class="tb-tabs" v-model="activeName">
      <el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
        <!-- <keep-alive> -->
        <contest-detail
          v-if="activeName==item.key"
          :type="item.key"
          :contestInfo="editData"
          :scrollTopTable="scrollTopTable"
        ></contest-detail>
        <!-- </keep-alive> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ContestDetail from '@/components/research/detail-pane'

export default {
  watch: {
    activeName (val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: val,
          editData: this.$route.query.editData,
        }
      })
    },
  },
  components: { ContestDetail },
  inject: ['reload'],
  data () {
    return {
      editData:{},
      activeName: 'Info',
      tabMapOptions: [
        { label: '竞赛信息', key: 'Info' },
        { label: '竞赛介绍', key: 'Introduce' },
        { label: '竞赛学员', key: 'Student' },
        { label: '竞赛题目', key: 'Problem' },
        { label: '提交记录', key: 'SubmiteRecord' },
        { label: '竞赛排名', key: 'Sort' },
        { label: '管理员', key: 'Administrater' }
      ],
      scrollTopTable: 0
    }
  },
  created () {
   this.editData = JSON.parse(this.$route.query.editData)
  },

  methods: {

    back () {
      this.$router.go(-1)
    },

    getScroll (event) {
      // console.log(event.target.scrollTop)
      this.scrollTopTable = event.target.scrollTop
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
