<template>
  <el-row class="table">
    <el-row class="row-bg" :gutter="24">
      <el-col :span="5">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputVal"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
            <el-option label="问题ID" value="1"></el-option>
            <el-option label="问题标题" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>

      <el-col :span="18">
        <el-button type="primary" size="small" @click="search" class="tb-button">搜 索</el-button>
        <el-button type="primary" size="small" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="small" @click="addProblems"  class="tb-button">编辑题目</el-button>
      </el-col>

    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="width: 40%;"
      border fit highlight-current-row ref="dragTable" row-key="problem_id">
    >
      <el-table-column label="题号" align="center">
        <template slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column label="ID" align="center" prop="problem_id"></el-table-column>
      <el-table-column label="标题" align="center" prop="title"></el-table-column>

    </el-table>
    <add-problem :show="addproblemDialog" :problemList="dataList" @updateView="getQuestionByLibraryId" :contest_id="editData.contest_id + ''"  @close="addproblemDialog=false"  ></add-problem>
  </el-row>
</template>

<script>

  import {getQuestionByLibraryId ,sortQuestionLibrary} from '@/api/research/contest'
  import addProblem from '@/components/research/addProblem'
  export default {
    components: {
      addProblem
    },
    props: {
      editData: {
        default: () => {
          return {}
        },
        type: Object
      },
    },
    data() {
      return {
        addproblemDialog:false,
        inputVal:'',
        select:'1',
        dataList: [],
        addMoudleDialog: false,
        coursewareList:[],
      }
    },
    created() {
      this.getQuestionByLibraryId()
    },
    methods: {
      addProblems() {
        this.addproblemDialog = true
      },

      // 获取模板列表
      async getQuestionByLibraryId() {
        const response = await getQuestionByLibraryId({
          contest_id:this.editData.contest_id,
          problem_id: this.select === '1' ? this.inputVal:'',
          title: this.select === '2' ? this.inputVal:'',
        })
        this.dataList = response.body
      },

      // 重置
      reset() {
        this.inputVal = ""
        this.select = "1"
      },

      // 搜索
      search() {
        this.getQuestionByLibraryId()
      },

      add() {
        this.addMoudleDialog = true
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/table.scss";
  .dialog-footer {
    text-align: left;
    margin-left: 50px;
    margin-bottom: 35px;
  }
</style>
