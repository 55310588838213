var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "row-bg", attrs: { gutter: 24 } },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "竞赛名称：", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入竞赛名称", maxlength: "20" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开放报名：", prop: "opentype" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.radioChange },
                  model: {
                    value: _vm.formData.opentype,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "opentype", $$v)
                    },
                    expression: "formData.opentype",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("不开放")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("开放报名")]),
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("开放报名&报名审核"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "竞赛模式：", prop: "title" } },
            [
              _vm.editData.raceRule === "CA"
                ? _c("el-radio", [_vm._v("刷题")])
                : _c("el-radio", [_vm._v(_vm._s(_vm.editData.raceRule))]),
            ],
            1
          ),
          _vm.enrollTime
            ? _c(
                "el-form-item",
                { attrs: { label: "报名时间：", prop: "applyTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: _vm.applychecked,
                      type: "datetimerange",
                      "start-placeholder": "活动报名开始时间",
                      "end-placeholder": "活动报名结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$forceUpdate()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.formData.applyTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "applyTime", $$v)
                      },
                      expression: "formData.applyTime",
                    },
                  }),
                  _vm.editData.raceRule === "CA"
                    ? _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-left": "15px" },
                          on: { change: _vm.handlechecked },
                          model: {
                            value: _vm.applychecked,
                            callback: function ($$v) {
                              _vm.applychecked = $$v
                            },
                            expression: "applychecked",
                          },
                        },
                        [_vm._v("不限时")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "竞赛时间：", prop: "contestTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.checked,
                  type: "datetimerange",
                  "start-placeholder": "竞赛开始时间",
                  "end-placeholder": "竞赛结束时间",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "default-time": ["00:00:00"],
                },
                on: {
                  change: function ($event) {
                    return _vm.$forceUpdate()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
                model: {
                  value: _vm.formData.contestTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "contestTime", $$v)
                  },
                  expression: "formData.contestTime",
                },
              }),
              _vm.editData.raceRule === "CA"
                ? _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-left": "15px" },
                      on: { change: _vm.handleContestchecked },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("不限时")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "编程语言：", prop: "language" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.formData.language,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "language", $$v)
                    },
                    expression: "formData.language",
                  },
                },
                _vm._l(_vm.languageList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.updateQuestionLibrary },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }