var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "Info"
        ? _c("contestinfo", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "Introduce"
        ? _c("contestintroduce", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "Student"
        ? _c("conteststudent", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "Problem"
        ? _c("contestproblem", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "Sort"
        ? _c("contestsort", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "SubmiteRecord"
        ? _c("contestsubmite", { attrs: { editData: _vm.contestInfo } })
        : _vm.type === "Administrater"
        ? _c("contestadmin", { attrs: { editData: _vm.contestInfo } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }