<template>
  <el-row class="row-bg" :gutter="24">
  <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
    <el-form-item label="竞赛名称：" prop="title">
      <el-input v-model="formData.title" placeholder="请输入竞赛名称" maxlength="20"></el-input>
    </el-form-item>
    <el-form-item label="开放报名：" prop="opentype">
      <el-radio-group v-model="formData.opentype"  @change="radioChange">
        <el-radio :label="1">不开放</el-radio>
        <el-radio :label="2">开放报名</el-radio>
        <el-radio :label="3">开放报名&报名审核</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="竞赛模式：" prop="title">
      <el-radio v-if="editData.raceRule === 'CA'">刷题</el-radio>
      <el-radio v-else>{{editData.raceRule}}</el-radio>
    </el-form-item>
    <el-form-item  v-if="enrollTime"  label="报名时间：" prop="applyTime">
      <el-date-picker
        :disabled="applychecked"
        @change="$forceUpdate()"
        @blur="$forceUpdate()"
        v-model="formData.applyTime"
        type="datetimerange"
        start-placeholder="活动报名开始时间"
        end-placeholder="活动报名结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00']"
      ></el-date-picker>
      <el-checkbox v-if="editData.raceRule === 'CA'"  style="margin-left: 15px;" v-model="applychecked" @change="handlechecked">不限时</el-checkbox>
    </el-form-item>
    <el-form-item label="竞赛时间：" prop="contestTime">
      <el-date-picker
        :disabled="checked"
        @change="$forceUpdate()"
        @blur="$forceUpdate()"
        v-model="formData.contestTime"
        type="datetimerange"
        start-placeholder="竞赛开始时间"
        end-placeholder="竞赛结束时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00']"
      ></el-date-picker>
      <el-checkbox v-if="editData.raceRule === 'CA'"  style="margin-left: 15px;" v-model="checked" @change="handleContestchecked">不限时</el-checkbox>
    </el-form-item>
    <el-form-item label="编程语言：" prop="language">
      <el-select v-model="formData.language" multiple filterable placeholder="请选择">
        <el-option
          v-for="item in languageList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
    <el-row style="display: flex;justify-content: center">
      <el-button type="primary" size="small" @click="updateQuestionLibrary" class="tb-button">保存</el-button>
    </el-row>
  </el-row>
</template>

<script>
  import { getContextInfo,updateQuestionLibrary } from '@/api/research/contest'
  import { langmask } from '@/utils/langmask'
    export default {
      name: "contest-info",
      props: {
        editData: {
          default: () => {
            return {}
          },
          type: Object
        },
      },
      data () {
        let RichTimeVal = (rule, value, callback) => {
          if (!this.formData.applyTime || this.formData.applyTime.length === 0) {
            callback(new Error('请选择活动报名时间'))
          } else {
            callback()
          }
        };
        return {
          langmaskValue: '',
          languageList:langmask,
          enrollTime:false,
          applychecked:false,
          checked:false,
          dataList:[],
          formData: {
            title:"",
            applyTime:[],
            contestTime:[],
            language:[],
            opentype:-1,
          },
          rules: {
            title: [
              { required: true, message: '课程类别名称不能为空', trigger: 'blur' },
              { max: 20, message: '字数限制在20个字内', trigger: 'blur' }
            ],
            opentype:{ required: true, message: '开放报名不能为空', trigger: 'blur' },
            applyTime:{ required: true, message: '报名时间不能为空', trigger: 'blur' },
            // raceRule:{ required: true, message: '请选择赛制模式', trigger: 'blur' },
            contestTime:{ required: true, message: '请选择竞赛时间', trigger: 'blur' },
            language:{ required: true, message: '请选择编程语言', trigger: 'blur' },
          }
        }
      },
      created () {
        this.getContextInfo()
      },
      methods:{
        handlechecked() {
          if (!this.applychecked) {
            this.formData.applyTime = []
          }
        },
        handleContestchecked() {
          if (!this.checked) {
            this.formData.contestTime = []
          }
        },

        radioChange(value) {
          console.log(this.formData.opentype)
          if(this.formData.opentype === 1) {
            this.enrollTime = false
            this.formData.applyTime = ['0001-01-01 00:00:00', '9999-12-31 23:59:59']
          }else {
            this.enrollTime = true
            this.formData.applyTime = []
          }
        },

         updateQuestionLibrary() {
          if(this.checked) {
            this.formData.contestTime = ['0001-01-01 00:00:00', '9999-12-31 23:59:59']
          }

          if(this.applychecked) {
            this.formData.applyTime = ['0001-01-01 00:00:00', '9999-12-31 23:59:59']
          }

          this.$refs["formData"].validate( async valid => {
            if (valid) {
              this.langmaskValue = ''
              this.formData.language.forEach((element, index) => {
                if (index === this.formData.language.length - 1) {
                  this.langmaskValue += element
                } else {
                  this.langmaskValue += element + ','
                }
              })
              const response = await updateQuestionLibrary({
                contest_id:this.editData.contest_id,
                title:this.formData.title,
                langmask:this.langmaskValue,
                startTime:this.checked ? '0001-01-01 00:00:00':this.formData.contestTime[0],
                endTime:this.checked ? '9999-12-31 23:59:59':this.formData.contestTime[1],
                isPrivate:this.formData.opentype  === 1 ? '1' : '0',
                isVerify:this.formData.opentype  === 3 ? 'Y' : 'N',
                startEnrollTime:this.applychecked ? '0001-01-01 00:00:00':this.formData.applyTime[0],
                endEnrollTime:this.applychecked ? '9999-12-31 23:59:59':this.formData.applyTime[1],
              })
              if(response && response.state === 'success') {
                window.$msg('编辑成功')
              }
            }
          })
        },
        // 查看竞赛详情
        async getContextInfo() {
          const response = await getContextInfo({
            contest_id:this.editData.contest_id,
            defunct:''
          })
          if(response && response.state === 'success') {
           this.contestInfo = response.body
            // if(this.contestInfo.raceRule !== null && this.contestInfo.raceRule !== undefined) {
            //   this.formData.raceRule = this.contestInfo.raceRule
            // }
            this.formData.title = this.contestInfo.title

            if(this.contestInfo.isPrivate === 1) {
              this.formData.opentype = 1
              this.enrollTime = false
            }else if (this.contestInfo.isPrivate === 0) {
              this.enrollTime = true
              if(this.contestInfo.isVerify === 'Y') {
                this.formData.opentype = 3
              }else if(this.contestInfo.isVerify === 'N') {
                this.formData.opentype = 2
              }
            }

            this.formData.language = this.contestInfo.langmask.split(',')

            if(this.contestInfo.endTime.indexOf('9999-12-31 23:59:59') !== -1) {
              this.checked = true
            }else {
              this.formData.contestTime.push(this.contestInfo.startTime)
              this.formData.contestTime.push(this.contestInfo.endTime)
            }

            if(this.contestInfo.endEnrollTime.indexOf('9999-12-31 23:59:59') !== -1 && this.contestInfo.raceRule === 'CA') {
              this.applychecked = true
            }else {
              this.applychecked = false
              this.formData.applyTime.push(this.contestInfo.startEnrollTime)
              this.formData.applyTime.push(this.contestInfo.endEnrollTime)
            }
          }
        },
      },
    }
</script>

<style scoped>
  .row-bg {
    background: #fff;
    margin-bottom: 15px;
    padding: 15px;
  }
</style>
