<template>
  <el-row>
    <el-card style="margin-bottom:10px;">
      <!-- activityContent：{{activityContent}} -->
      <Tinymce v-model="activityContent" width="100%" :height="500" />
      <el-row class="mt-20">
        <el-button @click="saveContent" size="small" type="primary">保 存</el-button>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import Tinymce from '@/components/newTinymce'
import { updateQuestionLibrary, getContextInfo } from '@/api/research/contest'

export default {
  components: {
    Tinymce
  },
  data () {
    return {
      activityContent: ''
    }
  },
  props: {
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },
  created () {
    this.getContextInfo()
  },
  methods: {
    // 查看活动信息
    async getContextInfo () {
      const res = await getContextInfo({
        contest_id:this.editData.contest_id,
        defunct:"",
      })
      if (res.body.description) {
        this.activityContent = res.body.description
      }
    },

    async saveContent () {
      await updateQuestionLibrary({
        contest_id:this.editData.contest_id,
        description: this.activityContent
      })
      window.$msg('编辑成功')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .toolbar {
  position: relative;
  z-index: 10001;
}
</style>
