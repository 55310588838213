var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "70%",
        top: "7vh",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("添加竞赛管理员")])]
      ),
      _c(
        "el-row",
        { attrs: { gutter: 60 } },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: { placeholder: "请输入内容" },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.search.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.inputVal,
                                callback: function ($$v) {
                                  _vm.inputVal = $$v
                                },
                                expression: "inputVal",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.select,
                                    callback: function ($$v) {
                                      _vm.select = $$v
                                    },
                                    expression: "select",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "用户名", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "姓名", value: "2" },
                                  }),
                                ],
                                1
                              ),
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.search },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      attrs: {
                        data: _vm.allList,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: {
                        "row-click": _vm.handleRowClick,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "70",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "用户名",
                          align: "center",
                          prop: "loginName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "姓名", align: "center", prop: "name" },
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticClass: "pagination",
                    attrs: {
                      "current-page": _vm.allPagination.currentPage,
                      "page-sizes": _vm.allPagination.pageSizes,
                      "page-size": _vm.allPagination.pageSize,
                      layout: _vm.allPagination.layout,
                      total: _vm.allPagination.total,
                    },
                    on: { "current-change": _vm.allhandleCurrentChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }