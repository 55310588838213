<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="75%"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>编辑题目</span>
    </div>
    <el-row>
      <el-row :gutter="60">
        <el-col :span="14">
          <span style="font-weight: bold;">待选题目：</span>
          <el-row class="label-item-left">
            <el-input placeholder="请输入内容" v-model="queryData.inputVal" style="width: 250px;margin-right: 20px"
                      @keyup.enter.native="search">
              <el-select style="width:110px" v-model="queryData.select" slot="prepend">
                <el-option label="问题标题" value="1"></el-option>
                <el-option label="问题id" value="2"></el-option>
              </el-select>
            </el-input>
            <el-select
              v-model="queryData.seleTag"
              multiple
              placeholder="请选择"
              style="width: 150px;margin-right: 20px;"
              @change="handleSeleTag"
            >
              <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
            <el-button type="info" @click="search" size="small">筛 选</el-button>
            <el-button type="primary" @click="reset" size="small">重 置</el-button>
          </el-row>

          <el-row class="label-item-left">
            <el-table
              ref="multipleTable"
              :data="dataList"
              border
              fit
              highlight-current-row
              class="tb-list"
              @selection-change="handleSeleChange"
            >
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column label="问题ID" align="center" width="200" prop="problem_id"></el-table-column>
              <el-table-column label="问题标题" align="left" prop="title"></el-table-column>
              <el-table-column label="标签" align="center">
                <template slot-scope="scope">
                  <template v-if="!scope.row.tagList || scope.row.tagList.length === 0">
                    <span>无</span>
                  </template>
                  <template v-else>
              <span v-for="item of scope.row.tagList" :key="item.id">
                <template v-if="Boolean(item.groupInfo)">
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+item.groupInfo.color"></span>
                </template>
                <template v-else>
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
                </template>
              </span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column label="添加" align="center">
                <template slot-scope="scope">
            <span
              class="btn-span"
              v-show="scope.row.choosetype == 0"
              @click="addQues(scope.row, scope.$index)"
            >添加</span>
                  <span class="btn-hasspan" v-show="scope.row.choosetype == 1">已添加</span>
                </template>
              </el-table-column>
            </el-table>
          </el-row>

          <el-row class="label-item-left">
            <el-dropdown trigger="click" class="tb-dropdown" style="margin-top: 20px;">
              <el-button type="info" size="medium">
                批量操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu style="padding: 10px;" slot="dropdown">
                <el-dropdown-item @click.native="enableAccount"><i class="el-icon-circle-plus-outline"></i>批量添加
                </el-dropdown-item>
                <el-dropdown-item @click.native="disabledAccount"><i class="el-icon-delete"></i>批量删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-row>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.currentPage"
            :page-sizes="pagination.pageSizes"
            :page-size="pagination.pageSize"
            :layout="pagination.layout"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="10">
          <span style="font-weight: bold;">已选题目：</span>
          <el-row class="haschoose">
            <el-table
              ref="dragTable"
              :data="seleTopic"
              border
              fit
              row-key="problem_id"
              highlight-current-row
              class="tb-list"
            >
              <el-table-column label="题号" align="center">
                <template slot-scope="scope">
                  {{scope.$index + 1}}
                </template>
              </el-table-column>
              <el-table-column label="ID" align="center" prop="problem_id"></el-table-column>
              <el-table-column label="标题" align="center" prop="title"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <i class="el-icon-sort" style="font-size: 16px;margin-right: 10px;cursor: pointer"></i>
                  <i class="el-icon-delete-solid" style="font-size: 16px;cursor: pointer"
                     @click="deleteItem(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <!-- footer -->
    <el-row slot="footer" class="footerparent">
      <el-button type="primary" @click="updateQuestionLibrary" size="small">保存</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  /**
   * 弹出框-使用封装
   * @attribute show 弹出框状态
   * @attribute problemList 根据有无 problemList数据 动态渲染 dialog弹出框 数据 和 弹框标题
   * @function close 自定义弹窗关闭事件
   */

  import Sortable from 'sortablejs'
  import {getQuestionList} from '@/api/research/question'
  import {getTags} from '@/api/research/tag'
  import getCourseType from '@/mixins/getCourseType'
  import {updateQuestionLibrary, deleteContestProblem} from '@/api/research/contest'

  export default {
    components: {
      Sortable
    },
    mixins: [getCourseType],
    props: {
      show: {
        default: false,
        type: Boolean
      },
      contest_id: {
        default: '',
        type: String
      },
      problemList: {
        default: () => {
          return []
        },
        type: Array
      },
    },
    watch: {
      show() {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      }
    },
    data() {
      return {
        seleTopic: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        queryData: {
          inputVal: '',
          select: '1',
          seleTag: '',
          tagId: ''
        },
        tagList: [], // 已启用的标签
        dataList: [],
        newList: [],
        oldList: [],
        multipleSelection: [],
      }
    },
    methods: {
      setSort() {
        const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
        Sortable.create(el, {
          ghostClass: 'sortable-ghost',
          setData: (dataTransfer) => {
            dataTransfer.setData('Text', '')
          },
          onEnd: evt => {
            const targetRow = this.seleTopic.splice(evt.oldIndex, 1)[0]
            this.seleTopic.splice(evt.newIndex, 0, targetRow)
            const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
            this.newList.splice(evt.newIndex, 0, tempIndex)
            this.$forceUpdate()
          }
        })
      },
      //删除关联题目
      async deleteContestProblem(cpid, problemId) {
        const response = await deleteContestProblem({
          cpid: cpid,
        })
        if (response && response.state === 'success') {
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.dataList[i].problem_id === problemId) {
              this.dataList[i].choosetype = 0
            }
          }
          for (let i = 0; i < this.seleTopic.length; i++) {
            if (this.seleTopic[i].problem_id === problemId) {
              this.seleTopic.splice(i, 1)
            }
          }
          window.$msg('删除成功')
          this.getQuestionList()
        }
      },
      // 删除
      deleteItem(row) {
        this.$confirm('是否删除此题目', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            if (row.cpid === null || row.cpid === undefined) {
              for (let i = 0; i < this.dataList.length; i++) {
                if (this.dataList[i].problem_id === row.problem_id) {
                  this.dataList[i].choosetype = 0
                }
              }
              for (let i = 0; i < this.seleTopic.length; i++) {
                if (this.seleTopic[i].problem_id === row.problem_id) {
                  this.seleTopic.splice(i, 1)
                }
              }
              window.$msg('删除成功')
            } else {
              this.deleteContestProblem(row.cpid, row.problem_id)
            }
          })
          .catch(() => {
            return false
          })
      },
      async updateQuestionLibrary() {
        let problem_ids = ""
        if (this.seleTopic.length === 0) {
          window.$msg('请先选择题目', 2)
          return false
        } else {
          this.seleTopic.forEach(item => {
            if (problem_ids === '') {
              problem_ids = item.problem_id
            } else {
              problem_ids = problem_ids + ',' + item.problem_id
            }
          })
        }

        const response = await updateQuestionLibrary({
          contest_id: this.contest_id,
          problem_ids: problem_ids
        })
        if (response && response.state === 'success') {
          window.$msg('题目编辑成功')
          this.close()
          this.$emit('updateView')
        }
      },
      openDialog() {
        this.seleTopic = []
        this.problemList.forEach((item)=> {
          this.seleTopic.push(item)
        })

        this.getQuestionList()
        this.getTags()

        this.oldList = this.seleTopic.map(v => v.problem_id)
        this.newList = this.oldList.slice()
        this.$nextTick(() => {
          this.setSort()
        })
      },

      // 关闭弹窗
      close() {
        this.$emit('close')
      },

      // 遍历树形结构
      getTreeArray(data, val) {
        for (let i in data) {
          if (data[i].id === val[0]) {
            if (data[i].pid !== '') {
              this.formData.selected.unshift(data[i].pid)
              this.getTreeArray(this.categoryList, this.formData.selected)
            }
          } else {
            if (data[i].hasOwnProperty('children')) {
              this.getTreeArray(data[i].children, val)
            }
          }
        }
      },

      // 选择标签
      handleSeleTag(tagId) {
        let problemIds = ''
        problemIds = tagId[0]
        for (let i = 1; i < tagId.length; i++) {
          problemIds = problemIds + ',' + tagId[i]
        }
        if (problemIds === undefined) {
          this.queryData.tagId = null
        } else {
          this.queryData.tagId = problemIds
        }
      },

      // 获取题目列表
      async getQuestionList() {
        const res = await getQuestionList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          defunct: 'N',
          title: this.queryData.select === '1' ? this.queryData.inputVal : '',
          problem_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
          tag_ids: this.queryData.tagId
        })
        this.pagination.total = res.body.total
        for (let i = 0; i < res.body.list.length; i++) {
          res.body.list[i].choosetype = 0
        }
        this.total = res.body.total
        this.dataList = res.body.list
        for (let i = 0; i < this.seleTopic.length; i++) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (this.seleTopic[i].problem_id === this.dataList[j].problem_id) {
              this.dataList[j].choosetype = 1
            }
          }
        }
      },

      // 获取启用的标签
      async getTags() {
        const res = await getTags(
          {
            pageNum: 1,
            pageSize: 10000,
            businessType: 'oj'
          }
        )
        this.tagList = res.body
      },

      jointArr(row) {
        let tagArr = []
        tagArr = row.split(',')
        return tagArr
      },

      // 重置
      reset() {
        this.queryData = {
          inputVal: '',
          select: '1',
          seleTag: '',
          tagId: ''
        }
      },
      // 查询题目
      search() {
        if (this.queryData.select === '2') {
          let reg = /^[0-9]*$/
          if (!reg.test(this.queryData.inputVal)) {
            window.$msg('请输入正确的id', 2)
            return false
          }
        }
        this.pagination.currentPage = 1
        this.getQuestionList()
      },

      handleSeleChange(val) {
        this.multipleSelection = val
      },
      // 添加题
      addQues(row, index) {
        this.dataList[index].choosetype = 1
        this.seleTopic.push(row)
      },
      // 批量添加数据
      enableAccount() {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let addflag = true
          for (let j = 0; j < this.seleTopic.length; j++) {
            if (
              this.multipleSelection[i].problem_id === this.seleTopic[j].problem_id
            ) {
              addflag = false
              break
            }
          }
          if (addflag) {
            for (let j = 0; j < this.dataList.length; j++) {
              if (
                this.multipleSelection[i].problem_id ===
                this.dataList[j].problem_id
              ) {
                this.dataList[j].choosetype = 1
              }
            }
            this.seleTopic.push(this.multipleSelection[i])
          }
          addflag = true
        }
        this.$refs.multipleTable.clearSelection()
        console.log(JSON.stringify(this.seleTopic))
      },

      // 批量删除
      disabledAccount() {
        let deleCurrnt = 0
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let addflag = true
          for (let j = 0; j < this.seleTopic.length; j++) {
            if (
              this.multipleSelection[i].problem_id === this.seleTopic[j].problem_id
            ) {
              addflag = false
              deleCurrnt = j
              break
            }
          }
          if (!addflag) {
            for (let k = 0; k < this.dataList.length; k++) {
              if (
                this.multipleSelection[i].problem_id ===
                this.dataList[k].problem_id
              ) {
                this.dataList[k].choosetype = 0
              }
            }
            this.seleTopic.splice(deleCurrnt, 1)
          }
          addflag = true
        }
        this.$refs.multipleTable.clearSelection()
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getQuestionList()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getQuestionList()
      },


    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .label-item-left {
    margin-top: 20px;
  }

  .footerparent {
    display: flex;
    justify-content: center;
  }

  .tag {
    display: inline-block;
    color: #fff;
    border-radius: 17px 20px 0px 17px;
    padding: 3px 15px;
    margin-right: 10px;
    margin-bottom: 8px;
  }

  .haschoose {
    margin-top: 20px;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    padding-top: 0px;
  }

  .seleRow {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background: #efefef;
    box-sizing: border-box;
    padding-left: 25px;
  }

  .seleNum {
    color: #fff;
    border-radius: 50%;
    padding: 1px 8px;
    background: #cccccc;
  }

  @import "../../../style/dialog.scss";
</style>
