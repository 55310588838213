<template>
  <el-row class="table">
    <el-row class="row-bg" :gutter="24">
      <el-col :span="5">
        <el-input
          placeholder="请输入搜索内容"
          v-model="inputVal"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
            <el-option label="用户名" value="1"></el-option>
            <el-option label="姓名" value="2"></el-option>
          </el-select>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-select v-model="status" placeholder="请选择状态">
          <el-option
            v-for="item in statusList"
            :key="item.name"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-col>

      <el-col :span="15">
        <el-button type="primary" size="small" @click="search" class="tb-button">搜 索</el-button>
        <el-button type="primary" size="small" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="small" @click="add" class="tb-button">添加成员</el-button>
        <el-button type="primary" size="small" @click="batchPass" class="tb-button">批量通过</el-button>
        <el-button type="primary" size="small" @click="batchRefund" class="tb-button">批量拒绝</el-button>
      </el-col>

    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      ref="studentTable"
      highlight-current-row
      class="tb-list"
      style="width: 65%"
      :row-key="getRowKeys"
      @selection-change="handleselectChange"
    >
      <el-table-column type="selection" width="70" align="center" :reserve-selection="true"></el-table-column>
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="报名时间" align="center" prop="createTime"></el-table-column>
      <el-table-column label="状态" align="center" prop="status"></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-row v-if="scope.row.status === '待审核'">
            <el-button size="small" type="primary" plain @click="verifySuccess(scope.row)">通过</el-button>
            <el-button size="small" type="primary" plain @click="verifyFailed(scope.row)">拒绝</el-button>
          </el-row>
          <el-row v-else-if="scope.row.status === '正常' || scope.row.status === '审核失败'">
            <el-button size="small" type="primary" plain @click="deleteItem(scope.row)">删除</el-button>
          </el-row>

        </template>
      </el-table-column>
    </el-table>
    <add-contest-student :show="addcontestDialog" @updateView = 'getContestUserList' :contestId = "editData.contest_id+''" @close="addcontestDialog = false"></add-contest-student>
    <el-pagination
      style="width: 65%;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>

  import { getContestUserList,deleteUser,verifySuccess,verifyFailed } from '@/api/research/contest'
  import addContestStudent from '@/components/research/detail-pane/addContestStudent'

  export default {
    components: {
      addContestStudent
    },
    props: {
      editData: {
        default: () => {
          return {}
        },
        type: Object
      },
    },
    data() {
      return {
        addcontestDialog:false,
        status:'',
        statusList:[
          {
            name:'待审核'
          },
          {
            name:'正常'
          },
          {
            name:'审核失败'
          }
        ],
        inputVal:'',
        select:'1',
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        moduleTitle: "",
        creatorName: "",
        multipleSelection:[],
      }
    },
    created() {
      this.getContestUserList()
    },
    methods: {
      getRowKeys (row) {
        return row.contestUserId
      },

      handleselectChange(val) {
        this.multipleSelection = val
      },
      batchPass() {
        if(this.multipleSelection.length === 0) {
          window.$msg('请先选择要审批的用户',2)
          return false
        }else {
          let flag = false
          for (let i = 0; i < this.multipleSelection.length; i++) {
            if(this.multipleSelection[i].status === '审核失败') {
              flag = true
              break;
            }
          }
          if(flag) {
            this.$confirm('当前审批学员中有审核失败的状态学员，是否继续通过审核？', {
              confirmButtonText: '继续通过',
              cancelButtonText: '返回修改',
              type: 'warning'
            })
              .then(async () => {
                this.batchVerifySuccess()
              })
              .catch(() => {
                return false
              })
          }else {
            this.batchVerifySuccess()
          }
        }
      },
      batchRefund() {
        if(this.multipleSelection.length === 0) {
          window.$msg('请先选择要审批的用户',2)
          return false
        }else {
          let flag = false
          for (let i = 0; i < this.multipleSelection.length; i++) {
            if(this.multipleSelection[i].status === '正常') {
              flag = true
              break;
            }
          }
          if(flag) {
            this.$confirm('当前审批学员中有审核通过的学员，是否继续拒绝？', {
              confirmButtonText: '继续通过',
              cancelButtonText: '返回修改',
              type: 'warning'
            })
              .then(async () => {
                this.batchVerifyFailed()
              })
              .catch(() => {
                return false
              })
          }else {
            this.batchVerifyFailed()
          }
        }
      },
      async batchVerifySuccess() {
        let thembArray =  []
        this.multipleSelection.forEach(item => {
          thembArray.push(item.contestUserId)
        })
        const response = await verifySuccess(JSON.stringify(thembArray))
        if(response && response.state === 'success') {
          window.$msg('批量审核完成')
          this.$refs.studentTable.clearSelection();
          this.getContestUserList()
        }
      },
      async batchVerifyFailed() {
        let thembArray =  []
        this.multipleSelection.forEach(item => {
          thembArray.push(item.contestUserId)
        })
        const response = await verifyFailed(JSON.stringify(thembArray))
        if(response && response.state === 'success') {
          window.$msg('批量审核完成')
          this.$refs.studentTable.clearSelection();
          this.getContestUserList()
        }
      },
      // 审核通过竞赛学员
      async verifySuccess(row) {
        let thembArray =  []
        thembArray.push(row.contestUserId)
        const response = await verifySuccess(JSON.stringify(thembArray))
        if(response && response.state === 'success') {
          window.$msg('审核通过')
          this.getContestUserList()
        }
      },
      // 审核失败竞赛学员
      async verifyFailed(row) {
        let thembArray =  []
        thembArray.push(row.contestUserId)
        const response = await verifyFailed(JSON.stringify(thembArray))
        if(response && response.state === 'success') {
          window.$msg('拒绝成功')
          this.getContestUserList()
        }
      },
      // 删除竞赛学员
      async deleteUser(row) {
        const response = await deleteUser({
          contestUserId:row.contestUserId
        })
        if(response && response.state === 'success') {
          window.$msg('删除成功')
          this.getContestUserList()
        }
      },
      //查询竞赛学员
      async getContestUserList() {
        const response = await getContestUserList({
          needCount:true,
          pageNum:this.pagination.currentPage,
          pageSize:this.pagination.pageSize,
          contestId:this.editData.contest_id,
          loginName:this.select === '1' ? this.inputVal:'',
          name:this.select === '2' ? this.inputVal:'',
          status:this.status
        })
        if(response && response.state === 'success') {
          this.dataList = response.body.list
          this.pagination.total = response.body.total
        }
      },

      // 重置
      reset() {
        this.inputVal = ""
        this.select = "1"
        this.status = ""
      },

      // 搜索
      search() {
        this.pagination.currentPage = 1
        this.getContestUserList()
      },

      add() {
        this.addcontestDialog = true
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getContestUserList()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getContestUserList()
      },

      // 删除
      deleteItem(row) {
        this.$confirm('是否删除当前学员', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            this.deleteUser(row)
          })
          .catch(() => {
            return false
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/table.scss";
</style>
