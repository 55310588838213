var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加成员")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { width: "40%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "账号", align: "center", prop: "loginName" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "角色", align: "center", prop: "role" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.role === "管理员"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add-contest-admin", {
        attrs: {
          show: _vm.addMoudleDialog,
          contestId: _vm.editData.contest_id + "",
        },
        on: {
          updateView: _vm.getContestManagerList,
          close: function ($event) {
            _vm.addMoudleDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }