<template>
  <div>
    <contestinfo v-if="type === 'Info'" :editData="contestInfo"></contestinfo>
    <contestintroduce v-else-if="type === 'Introduce'" :editData="contestInfo"></contestintroduce>
    <conteststudent v-else-if="type === 'Student'" :editData="contestInfo"></conteststudent>
    <contestproblem v-else-if="type === 'Problem'" :editData="contestInfo"></contestproblem>
    <contestsort  v-else-if="type === 'Sort'" :editData="contestInfo"></contestsort>
    <contestsubmite v-else-if="type === 'SubmiteRecord'" :editData="contestInfo"></contestsubmite>
    <contestadmin v-else-if="type === 'Administrater'" :editData="contestInfo"></contestadmin>
  </div>
</template>

<script>
import contestinfo from './contest-info'
import contestintroduce from './contest-introduce'
import conteststudent from './contest-student'
import contestproblem from './contest-problem'
import contestsort from './contest-sort'
import contestsubmite from './contest-submite'
import contestadmin from './contest-admin'

export default {
  props: {
    type: {
      default: 'Info',
      type: String
    },
    contestInfo: {
      default: () => {
        return {}
      },
      type: Object
    },
    scrollTopTable: {
      default: 0,
      type: Number
    }
  },
  components: {
    contestinfo,
    conteststudent,
    contestproblem,
    contestsort,
    contestsubmite,
    contestadmin,
    contestintroduce
},
  data () {
    return {}
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
